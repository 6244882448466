.DevelopmentStepsContainer {
  left: 16%;
  top: 15%;
  position: absolute;
  width: 84%;
  height: 82.5%;
  display: flex;
}
.DevelopmentStepsContainerLoading {
  left: 24%;
  top: 16%;
  position: absolute;
  width: 76%;
  height: 82.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ScreenShareCheckBox {
  height: 20%;
  width: 10%;
  position: relative;
  top: 23%;
}
.BottomNavBar {
  width: 100%;
  height: 10%;
}

.BasicInfoStepIcon {
  background-color: #3045ff;
  width: 30px;
  height: 30px;
  left: 0%;
  top: 5%;
  position: absolute;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VerticalAssignmentCreationStepsLane {
  position: absolute;
  top: 16%;
  left: 16%;
  width: 6%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: left;
  border-right: solid 2px #adadad;
}

.backtoModulesReview {
  color: black;
  background: transparent;
  border-color: transparent;
  left: 10%;
  position: absolute;
  height: 30px;
  top: 80%;
  width: 10%;
  font-weight: 500;
}
.PersonaCheckBoxContainer {
  color: black;
  background: transparent;
  border-color: transparent;
  left: 10%;
  height: 8%;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.ModuleAssignmentButtonPopUp {
  border-radius: 5px;
  background: #3045ff;
  color: white;
  border: transparent;
  top: 80%;
  left: 70%;
  width: 20%;
  height: 10%;
  position: absolute;
  font-weight: normal;
  padding: 5px 5px 5px 5px;
}
.ModuleInviteUserPopUpHeader {
  color: #000;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  left: 10%;
  width: 70%;
  top: 5%;
  position: relative;
}
.ModuleInviteUserPopUpSubHeader {
  color: #696767;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  top: 5%;
  left: 10%;
  width: 70%;
  position: relative;
}
.ModuleAssignmentButtonPopUp:hover {
  cursor: pointer;
}
.ModuleAssignUserPopUp {
  border-radius: 5px;
  left: 30%;
  width: 40%;
  top: 15%;
  height: 25%;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
  max-height: 45%;
}
.BasicInfoStepInputBox {
  position: absolute;
  top: 6%;
  left: 0%;
  width: 90%;
  height: 60%;
  border-radius: 5px;
}
.PersonaInfoStepInputBox {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 90%;
  height: 60%;
  border-radius: 5px;
}
.ScenarioInfoStepInputBox {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 90%;
  height: 60%;
  border-radius: 5px;
}
.RepStepInactive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.ProspectStepManagerActive:hover {
  cursor: pointer;
}
.ProspectStepManagerActive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: #3045ff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.RepStepActive:hover {
  cursor: pointer;
}
.RepStepActive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: #3045ff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.DetailsStepActive:hover {
  cursor: pointer;
}
.LaneStepInactive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 300;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.LaneStepActive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: #3045ff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  text-align: left;
  font-weight: 500;
  margin: 10px 0px 10px 0px;
}
.DetailsStepActive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: #3045ff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  text-align: left;
  font-weight: 500;
  margin: 10px 0px 10px 0px;
}
.ProspectStepManagerInactive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.DetailsStepInactive {
  background: transparent;
  width: fit-content;
  height: 100%;
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.greyArrowManagerSteps {
  background-image: url("./ActivityCreationPageIcons/left-arrow_white.png");
  width: 8%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0.7vw;
  transform: rotate(180deg);
}
.blueArrowManagerSteps {
  background-image: url("./ActivityCreationPageIcons/left-arrow_blue.png");
  width: 8%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0.7vw;
  transform: rotate(180deg);
}
.AdditionalContextStepManagerInactive {
  background: transparent;
  height: fit-content;
  width: 100%;
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
  text-align: left;
  margin: 10px 0px 10px 0px;
}
.AdditionalContextStepManagerActive {
  background: transparent;
  width: fit-content;
  height: 100%;
  color: #3045ff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1vw;
  border: transparent;
  font-weight: 500;
}
.BasicInfoStepLabel {
  color: #3045ff;
  position: absolute;
  top: 6%;
  left: 8%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
}
.DueDateTimeLimitInputContainer {
  display: flex;
  width: 100%;
  height: 25%;
  flex-direction: row;
  border: transparent;
  border-radius: 5px;
}
.DueDateInputContainer {
  width: 25rem;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.TimeLimitInputContainer {
  width: 58%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.TestTypeContainer {
  width: 47.5%;
  height: 100%;

  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.ProductInputContainer {
  width: 47.5%;
  height: 100%;

  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.DevStepsLineInactive {
  width: 0.125rem;
  height: 34.6875rem;
  flex-shrink: 0;
  background: #f1f1f1;
  position: absolute;
  left: 45%;
  top: 8%;
}
.TestParticipantsInputContainer {
  display: flex;
  width: 100%;
  height: 25%;
  flex-direction: column;

  border-radius: 5px;
}
.ProductTestTypeInputContainer {
  display: flex;
  width: 100%;
  height: 20%;
  flex-direction: row;
  border: transparent;
  border-radius: 5px;
}
.BasicInfoStepLabel:hover {
  cursor: pointer;
}
.BasicToGradeLine {
  width: 15%;
  height: 10%;
  flex-shrink: 0;
  background-color: #3045ff;
  position: absolute;
  left: 42.5%;
  top: 8%;
}
.BasicToReviewLine {
  width: 15%;
  height: 20%;
  flex-shrink: 0;
  background-color: #3045ff;
  position: absolute;
  left: 42.5%;
  top: 8%;
}
.BasicToDiscReviewLine {
  width: 15%;
  height: 30%;
  flex-shrink: 0;
  background-color: #3045ff;
  position: absolute;
  left: 42.5%;
  top: 8%;
}
.DiscoveryCallLabel {
  position: absolute;
  font-size: 0.8375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  left: 5%;
  top: 42%;
}
.ProductKnowledgeLabel {
  position: absolute;
  font-size: 0.8375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  left: 24%;
  top: 42%;
}
.ProspectNameLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
}
.ProspectNameLabel::after {
  content: " *";
  color: #3045ff;
}
.ProspectNameInput {
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}
.ProspectCompanyLabel::after {
  content: " *";
  color: #3045ff;
}
.ProspectNameInput:focus {
  outline: none;
}
.AdditionalContextInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 55%;
  position: relative;
  left: 3%;
}
.AdditionalContextInput:focus {
  outline: none;
}
.ProspectCompanyLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
  flex-direction: column;
}
.ProspectCompanyInput:focus {
  outline: none;
}
.DifficultyContextLabel::after {
  content: " *";
  color: #3045ff;
}
.DifficultyContextLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
  flex-direction: column;
}
.ProspectCompanyInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}

.RepCompanyInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}
.RepCompanyInput:focus {
  outline: none;
}
.ProspectRoleLabel::after {
  content: " *";
  color: #3045ff;
}
.ProspectRoleLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
  flex-direction: column;
}

.ProspectRoleInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}
.ProspectRoleInput:focus {
  outline: none;
}
.GradingStepIconInactive {
  width: 0.9375rem;
  height: 0.9375rem;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  background: rgb(214, 214, 214);
  left: 18%;
  top: 80%;
}
.ReviewStepIconInactive {
  width: 60%;
  height: 3%;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  background: rgb(214, 214, 214);
  left: 20%;
  top: 92%;
}
.StepsLane {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 30px;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.GradingStepLabelInactive {
  color: rgb(214, 214, 214);
  position: absolute;
  top: 80%;
  left: 8%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.ReviewInfoStepLabelInactive {
  color: rgb(214, 214, 214);
  position: absolute;
  top: 92%;
  left: 8%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.ScenarioBackgroundLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 8%;
}
.TestNameLabel {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  /* position: relative;
    top: 10%;
    left: 3%; */
  width: 100%;
  height: 20%;
}
.InputSubLabel {
  font-size: 0.8vw;
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 20%;
  position: relative;
}
.SummaryInputSubLabel {
  color: grey;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 20%;
  position: relative;
  top: 10%;
  margin-bottom: 10px;
}
.ScenarioBackgroundSubLabel {
  color: grey;
  font-size: 0.8vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 8%;
}
.CoverPhotoLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
}
.TestNameLabel:after {
  content: " *";
  color: #3045ff;
}
.CoverPhotoPreview {
  width: 80%;
  left: 10%;
  height: 10%;
  position: absolute;
  top: 5%;
}
.EmptyCoverPhotoPreview {
  width: 80%;
  left: 10%;
  height: 10%;
  position: absolute;
  top: 5%;
  background-image: url("./ActivityCreationPageIcons/placeholder.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.DueLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 20%;
}
.TimeLimitLabel:after {
  content: " *";
  color: #3045ff;
}
.DueLabel:after {
  content: " *";
  color: #3045ff;
}
.TimeLimitLabel {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 20%;
}
.TestTypeLabel {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
}
.TestTypeLabel:after {
  content: " *";
  color: #3045ff;
}
.SummaryHeader {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  width: 100%;
  height: 20%;
}
.TestDescLabel:after {
  content: " *";
  color: #3045ff;
}
.TestDescLabel {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  width: 100%;
  height: 20%;
}
.ScreenShareDesc {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  width: 90%;
  height: 20%;
  top: 20%;
}

.TestParticipantsLabel {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 40%;
}
.TestParticipantsLabel:after {
  content: " *";
  color: #3045ff;
}
.SaveContinueActivityButtonFirst {
  border-radius: 5px;
  position: relative;
  left: 85%;
  background-color: #3045ff;
  top: 10%;
  width: 15%;
  height: 100%;
  flex-shrink: 0;
  border: transparent;
  color: white;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.SaveContinueActivityButtonFirst:hover {
  background-color: #2434c4;
  cursor: pointer;
}
.SaveContinueActivityButton {
  border-radius: 5px;
  position: relative;
  left: 81%;
  background-color: #3045ff;
  top: 10%;
  width: 15%;
  height: 100%;
  flex-shrink: 0;
  border: transparent;
  color: white;
  font-size: 0.75rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  /* font-weight: 700; */
}
.SaveContinueActivityButton:hover {
  background-color: #2434c4;
  cursor: pointer;
}
.SaveContinueButton {
  border-radius: 5px;
  position: absolute;
  left: 70%;
  background-color: #3045ff;
  top: 85%;
  width: 10.5rem;
  height: 1.6875rem;
  flex-shrink: 0;
  border: transparent;
  color: white;
  font-size: 0.75rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  /* font-weight: 700; */
}
.SaveContinueButton:hover {
  background-color: #2434c4;
  cursor: pointer;
}
.ModulePageInputContainer {
  display: flex;
  width: 100%;
  height: 25%;
  flex-direction: column;
  border-radius: 5px;
}
.ScreenShareInputContainer {
  display: flex;
  width: 28%;
  height: 20%;
  border-radius: 5px;
  flex-direction: row;
}
.ScenarioBackgroundInputContainer {
  display: flex;
  width: 100%;
  height: 65%;
  flex-direction: column;
  border-radius: 5px;
}

.CoverPhotoInputContainer {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
  /* display: flex;
    flex-direction: row; */
}
.CoverPhotoInput {
  color: transparent;
  font-size: 1vw;
  width: 15%;
  height: 80%;
}
.StepsHeader {
  font-weight: 500;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 30px;
  color: black;
  position: relative;
  top: 5%;
}
.LabelInputSpacerDiv {
  height: 15%;
  font-weight: 500;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: start;
  width: 30%;
}
.PersonaDocFilename {
  border: 2px solid #ccc;
  display: inline-block;
  padding: 6px 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  border-radius: 5px;
  width: 100%;
}
.TestNameInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  background-color: transparent;
  border: transparent;
  height: 60%;
  position: relative;
}
.LinksLabel {
  color: #000;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  width: 100%;
  height: 20%;
}
.ScenarioBackgroundInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  background-color: transparent;
  border: transparent;
  height: 70%;
  position: relative;
}
.TestNameInput:focus {
  outline: none;
}
.TestDueInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  background-color: transparent;
  border: transparent;
  height: 50%;
  position: relative;
}
.TestDueInput:focus {
  outline: none;
}
.TestTimeLimitInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  background-color: transparent;
  border: transparent;
  height: 55%;
  position: relative;
}
.TestTimeLimitInput:focus {
  outline: none;
}
.ActivityCreationTextInput {
  color: black;
  font-size: 14px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 100%;
  height: 70%;
  position: relative;
  top: 10%;
  border: solid 1px rgb(224, 224, 224);
  border-radius: 5px;
  padding: 0px 10px;
}
.ActivityCreationTextInput:focus {
  outline: none;
}
.TestTypeInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}
.TestTypeInput:focus {
  outline: none;
}
.ProductKnowledgeTypeInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}

.TestDescInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
}
.TestPersonaPresetInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 25rem;
  background-color: transparent;
  border: transparent;
  height: 65%;
  position: relative;
}
.TestDescInput:focus {
  outline: none;
}
.TestParticipantsInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 80%;
  background-color: transparent;
  border: transparent;
  height: 50%;
  position: relative;
  left: 10%;
  width: 80%;
}
.TestParticipantsInput:focus {
  outline: none;
}
.GradingStepIconActive {
  background-color: #3045ff;
  width: 30px;
  height: 30px;
  left: 0%;
  top: 15%;
  position: absolute;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReviewStepIconActive {
  background-color: #3045ff;
  width: 30px;
  height: 30px;
  left: 0%;
  top: 25%;
  position: absolute;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DiscReviewStepIcon {
  background-color: #3045ff;
  width: 30px;
  height: 30px;
  left: 0%;
  top: 35%;
  position: absolute;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.GradingStepLabel {
  color: #3045ff;
  position: absolute;
  top: 16%;
  left: 8%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
}
.GradingStepLabel:hover {
  cursor: pointer;
}
.ReviewStepLabel {
  color: #3045ff;
  position: absolute;
  top: 26%;
  left: 8%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
}
.ReviewStepLabel:hover {
  cursor: pointer;
}

.DiscReviewStepLabel {
  color: #3045ff;
  position: absolute;
  top: 36%;
  left: 8%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
}
.GradingStepBox {
  position: absolute;
  top: 25%;
  left: 8%;
  width: 90%;
  height: 60%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
}
.ProspectContextStepBox {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 90%;
  height: 60%;
  border-radius: 5px;
}
.RepContextStepBox {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 90%;
  height: 60%;
  border-radius: 5px;
}
.AdditionalContextStepBox {
  position: absolute;
  top: 10%;
  left: 0%;
  width: 90%;
  height: 60%;
  border-radius: 0.625rem;
}
.GradingStepBoxLabel {
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  top: 5%;
  left: 5%;
}
.AdditionalContextLabel {
  color: #000;
  font-size: 0.9vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 30%;
  flex-direction: column;
}
.fileUpload {
  border-radius: 1.5625rem;
  position: absolute;
  left: 35%;
  top: 70%;
  width: 10.5rem;
  height: 1.6875rem;
  flex-shrink: 0;
  color: #3045ff;
  font-size: 0.75rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 700;
}
.fileUploadContainer {
  position: absolute;
  left: 20%;
  top: 18%;
  width: 60%;
  height: 15.0625rem;
  text-align: center;
  border: 1px solid #adadad;
}
.UploadImage {
  position: absolute;
  left: 40%;
  top: 15%;
  width: 20%;
  height: 20%;
  background-image: url("./ActivityCreationPageIcons/upload.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.UploadLabel {
  position: absolute;
  left: 35%;
  top: 40%;
  width: 30%;
  height: 20%;
  font-size: 0.75rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.9375rem;
  color: #adadad;
}
.EditBasicInfo {
  color: #3045ff;
  position: absolute;
  top: 6%;
  left: 90%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  background: transparent;
  border: transparent;
}
.EditBasicInfo:hover {
  cursor: pointer;
}
.EditGrading {
  color: #3045ff;
  position: absolute;
  top: 16%;
  left: 90%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  background: transparent;
  border: transparent;
}
.EditGrading:hover {
  cursor: pointer;
}
.EditAdditionalContext {
  color: #3045ff;
  position: absolute;
  top: 26%;
  left: 90%;
  font-size: 0.9375rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  background: transparent;
  border: transparent;
}
.EditAdditionalContext:hover {
  cursor: pointer;
}
.ActivityProgressContainer {
  left: 75%;
  top: 20%;
  position: absolute;
  width: 25%;
  height: 78%;
  border-radius: 0.625rem;
  word-wrap: break-word;
}
.ManagerDevPageDiv {
  left: 75%;
  top: 20%;
  position: absolute;
  width: 1px;
  height: 78%;
  background: #d9d9d9;
}
.ActivityProgressTestNameLabel {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 0%;
  font-weight: 500;
}
.ActivityProgressDueDateLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressDueDateContainer {
  left: 10%;
  top: 23%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressDurationContainer {
  left: 10%;
  top: 28%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressTestTypeContainer {
  left: 10%;
  top: 18%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressParticipantsContainer {
  left: 10%;
  top: 33%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressDurationLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressDescLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 38%;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: auto;
}
.ActivityProgressParticipantsLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressRepHeaderLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 48%;
  font-size: 1vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  color: #000;
  font-weight: 500;
}
.ActivityProgressProspectHeaderLabelCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 48%;
  font-size: 1vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  color: #000;
  font-weight: 500;
}
.ActivityProgressProspectHeaderLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 63%;
  font-size: 1vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  color: #000;
  font-weight: 500;
}
.ActivityProgressOptionalHeaderLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 70%;
  font-size: 1vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  color: #000;
  font-weight: 500;
}
.ActivityProgressOptionalHeaderLabelCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 10%;
  top: 60%;
  font-size: 1vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  color: #000;
  font-weight: 500;
}
.activityDevProgressBoxBottom {
  width: 100%;
  height: 35%;
  position: absolute;
  top: 40%;
  font-size: 1vw;
}
.ActivityProgressTestTypeLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityCreationButtonLabel {
  width: 55%;
  height: 100%;
  text-align: left;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ActivityCreationButtonLabel:hover {
  cursor: pointer;
}
.ActivityCreationButtonIcon {
  top: 30%;
  position: relative;
  width: 45%;
  height: 40%;
  left: 8%;
  background-image: url("./ActivityCreationPageIcons/send.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.ActivityCreationButton {
  border-radius: 5px;
  background-color: #3045ff;
  width: 47.5%;
  height: 100%;
  border: transparent;
  color: white;
  display: flex;
}
.ActivityTestButton {
  border-radius: 5px;
  background-color: white;
  border: solid 2px #3045ff;
  top: 9.5%;
  width: 12%;
  height: 5%;
  position: absolute;
  padding: 5px;
  left: 86%;
  color: #3045ff;
  display: flex;
}

.ActivityTestButtonInactive {
  border-radius: 5px;
  background-color: #d9d9d9;
  border: solid 1px#D9D9D9;
  width: 47.5%;
  height: 100%;
  color: white;
}
.ActivityCreationButtonInactive {
  border-radius: 5px;
  background-color: #d9d9d9;
  width: 47.5%;
  height: 100%;
  border: transparent;
  color: white;
}
.ActivityTestButton:hover {
  cursor: pointer;
  background-color: #d9d9d9;
}
.ActivityTestButtonLabel {
  width: 60%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}
.ActivityTestButtonLabel:hover {
  cursor: pointer;
}
.ActivityTestButtonIcon {
  top: 20%;
  position: relative;
  width: 30%;
  height: 60%;
  left: 0%;
  background-image: url("./ActivityCreationPageIcons/chemistry-lab-instrument.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.TestPublishButtonsContainer {
  border-radius: 5px;
  position: absolute;
  left: 10%;
  top: 92%;
  width: 80%;
  height: 2.5rem;
  flex-shrink: 0;
  border: transparent;
  color: white;
  font-size: 0.75rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  display: flex;
}
.ActivityCreationButton:hover {
  background-color: #2434c4;
  cursor: pointer;
}
.ActivityProgressFilanemLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 15%;
  top: 40%;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
}
.ActivityProgressProspectName {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectNameCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressCompany {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectCompanyCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectCompany {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectRole {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}

.ActivityProgressProspectRoleCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectDifficulty {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectDifficultyCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProspectAdditionalContext {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 15%;
  top: 80%;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: auto;
  width: 70%;
  height: 9%;
}
.ActivityProgressProspectAdditionalContextCC {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 15%;
  top: 70%;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: auto;
  width: 70%;
  height: 9%;
}
.PublishButtonInactive {
  border-radius: 5px;
  background: #d9d9d9;
  position: absolute;
  left: 10%;
  top: 92%;
  width: 80%;
  height: 2.5rem;
  flex-shrink: 0;
  border: transparent;
  color: white;
  font-size: 0.75rem;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdditionalContextSliderContainer {
  width: 70%;
  height: 20%;
  position: absolute;
  left: 13%;
  top: 70%;
}
.ProductIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/product.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.CallTypeIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/phone-call.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.CompanyIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/business-and-trade.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.ProspectNameIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/id-card.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.ProspectRoleIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/portfolio.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}

.PublishingLabel {
  color: rgb(150, 150, 150);
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.DevelopmentIcon {
  position: absolute;
  width: 70%;
  height: 100%;
  left: -45%;
  top: -10%;
  background-image: url("./ActivityCreationPageIcons/stairs_curr.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.ProductInput {
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  width: 92%;
  background-color: transparent;
  border: transparent;
  height: 30%;
  position: relative;
  left: 3%;
}
.ProductInput:focus {
  outline: none;
}
.ProductLabel {
  color: #000;
  font-size: 1vw;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 10%;
  left: 3%;
  width: 100%;
  height: 50%;
  flex-direction: column;
}
.ActivityProgressProductLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: #adadad;
  font-size: 0.7vw;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 90%;
}
.ActivityProgressProductContainer {
  left: 10%;
  top: 53%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectNameCCContainer {
  left: 10%;
  top: 53%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectNameContainer {
  left: 10%;
  top: 68%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressCompanyContainer {
  left: 10%;
  top: 58%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectCompanyCCContainer {
  left: 10%;
  top: 58%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectCompanyContainer {
  left: 10%;
  top: 73%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectRoleContainer {
  left: 10%;
  top: 78%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectDifficultyContainer {
  left: 10%;
  top: 75%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectRoleCCContainer {
  left: 10%;
  top: 63%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ActivityProgressProspectDifficultyCCContainer {
  left: 10%;
  top: 65%;
  position: absolute;
  width: 90%;
  height: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TestTypeIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/testType.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.ActivityProgressHomedueDateIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/calendar_grey.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.ActivityProgressHomeTimeLimitIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/clock_grey.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.ActivityProgressHomeTeamIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./ActivityCreationPageIcons/team_grey.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.CreationContextChatBotBox {
  width: 96%;
  height: 99%;
  border: solid 1px rgb(233, 233, 233);
  border-radius: 5px;
}
.ContextCreationBotInput {
  width: 100%;
  height: 100%;
  left: 0%;
  position: absolute;
  background: rgb(230, 230, 230);
  border-radius: 5px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  padding: 1% 10%;
  bottom: 0%;
  max-height: 300px;
  white-space: pre-wrap;
}
.ContextCreationBotInput:focus {
  outline: none;
}
.ContextCreationBotInputContainer {
  position: absolute;
  max-height: 7%;
  height: auto;
  top: 91%;
  left: 1%;
  width: 94%;
  background: rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.chatbotroleplayCreationContainer {
  position: absolute;
  width: 96%;
  height: 87%;
  top: 0%;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.UploadFileButton {
  background: rgb(194, 194, 194);
  background-image: url("./ActivityCreationPageIcons/upload_new.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 5px;
}
.SubmitChatButton {
  background: #3045ff;
  background-image: url("./ActivityCreationPageIcons/up-arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  border-radius: 5px;
}
textarea {
  resize: none;
}

.UploadFileButton:hover {
  /* background: rgb(124, 124, 124); */
  cursor: pointer;
}
.PublishModuleContainer {
  position: absolute;
  height: 10%;
  top: 90%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PublishModuleButton {
  width: 60%;
  height: 90%;
  background: #3045ff;
  border-radius: 5px;
  color: white;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PublishModuleButton:hover {
  cursor: pointer;
  background: #2434c4;
}
