.messageBoxUser {
  background: rgb(62, 155, 255);
  border-radius: 20px 20px 0px 20px;
  padding: 10px 10px;
  color: white;
  display: inline-block;
  max-width: 80%;
}
.messageBoxBot {
  background: #f0f3ff;
  border-radius: 20px 20px 20px 0px;
  padding: 10px 10px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageTextUser {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 14px;
  word-wrap: break-word;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  white-space: pre-line;
  color: white;
}
.messageTextBot {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 14px;
  word-wrap: break-word;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  white-space: pre-line;
  color: black;
}

.messageText img {
  vertical-align: middle;
}
.fileConfirmationButton {
  width: max-content;
  height: max-content;
  background: #3045ff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
}
.fileConfirmationButton:hover {
  cursor: pointer;
  background: rgb(0, 48, 99);
}
.messageContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
  margin-right: 17px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #2979ff;
}

.backgroundLight {
  background: rgb(3, 5, 109);
}
