.pricingBox {
  width: 50%;
  height: 55%;
  background: #3045ff;
  border-radius: 15px;
  position: relative;
  top: 5%;
  left: 25%;
}
.PPHeader {
  text-align: center;
  font-weight: bold;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 0%;
}
.whyPareButtonLink {
  color: #000;
  font-size: 13px;
  background-color: transparent;
  border-color: transparent;
  font-weight: 500;
  width: 33.333333%;
  text-align: center;
  font-weight: 400;
}
.whyPareButtonLink:hover {
  color: #3045ff;
  cursor: pointer;
}
.howItWorksButtonLink {
  color: #000;
  font-size: 13px;
  background-color: transparent;
  border-color: transparent;
  font-weight: 500;
  width: 33.333333%;
  text-align: center;
}
.howItWorksButtonLink:hover {
  color: #3045ff;
  cursor: pointer;
}
.SubBannerPP {
  position: relative;
  left: 0;
  top: 3%;
  width: 100vw;
  height: 120px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
}
.topBannerPP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: #3045ff;
  text-align: center;
}
.backgroundDivPricingPage {
  background-size: 200% 200%;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  will-change: opacity, visibility;
}
.backgroundDivPricingPage.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.pricingBoxHeader {
  position: relative;
  top: 20%;
  left: 40%;
  color: white;
  font-weight: bold;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
}
.pricingBoxP {
  color: white;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: relative;
  top: 25%;
  left: 40%;
  width: 300px;
}
.pricingBoxRAB {
  font-size: 15px;
  font-weight: bold;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 40%;
  top: 75%;
  background-color: #3045ff;
  border-color: #1a258b;
  position: absolute;
  border-radius: 5px;
  width: 25%;
  height: 8%;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.pricingBoxRAB:hover {
  cursor: pointer;
  color: white;
  background: #1a258b;
}
.whyPareButtonPP {
  color: #000;
  font-size: 13px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  position: absolute;
  left: 35%;
  top: 40%;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
  background-color: transparent;
  border-color: transparent;
  font-weight: bold;
}

.whyZenoButtonPP:hover {
  color: #3045ff;
  cursor: pointer;
}
.PareLogoNoWords {
  color: black;
  background: transparent;
  border-color: transparent;
  left: 5%;
  position: absolute;
  height: 70%;
  width: 30%;
  top: 15%;
  background-image: url("./PricingPageIcons/PareJLogoW.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
