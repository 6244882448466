.IndividualActivityTitle {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: black;
  left: 10%;
  top: 10%;
  position: absolute;
}
.IndividualActivityBoxDueDateLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  color: #adadad;
  width: 90%;
}
.IndividualActivityBoxDurationLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  color: #adadad;
  width: 90%;
}
.IndividualActivityDescriptionLabel {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  color: #adadad;
  left: 10%;
  top: 45%;
  width: 80%;
  position: absolute;
  height: 30%;
  overflow: hidden;
}
.IndiModuleDueDateContainer {
  left: 10%;
  top: 25%;
  position: absolute;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
}
.IndiModuleDurationContainer {
  left: 10%;
  top: 35%;
  position: absolute;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
}
.IndividualActivityDueDateIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./IndividualActivityBoxIcons/calendar_grey.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.IndividualActivityClockIcon {
  border-color: transparent;
  background: transparent;
  background-image: url("./IndividualActivityBoxIcons/clock_grey.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 10%;
}
.IndividualActvityTeamViewButton {
  border-radius: 5px;
  background: #3045ff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  /* font-weight: bold; */
  width: 28%;
  height: 12%;
  position: absolute;
  left: 75%;
  top: 9%;
  border-color: transparent;
  color: white;
}
.IndividualActvityStartLabel {
  width: 30%;
  /* text-align:right; */
}
.IndividualActvityTeamViewButton:hover {
  background-color: #2434c4;
  cursor: pointer;
}
.IndividualActvityStartLabelContainer {
  border-radius: 5px;
  background: transparent;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
  width: 50%;
  height: 12%;
  position: absolute;
  /* left: 55%;
  top: 80%; */
  border-color: transparent;
  color: #3045ff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.IndividualActvityStartLabelContainer:hover {
  cursor: pointer;
}
.IndividualActvityTeamViewButtonInactiveContainer {
  border-radius: 5px;
  background: transparent;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-weight: 500;
  width: 50%;
  height: 12%;
  position: absolute;
  left: 55%;
  top: 85%;
  border-color: transparent;
  color: #3045ff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.IndividualActvityTeamViewButtonInactiveContainer:hover {
  cursor: pointer;
}
.IndividualActvityTeamViewButtonInactive {
  width: 40%;
}
.IndividualActvityTeamViewButtonInactive:hover {
  cursor: pointer;
}
.playIcon {
  width: 40%;
  border-color: transparent;
  background: transparent;
  background-image: url("./IndividualActivityBoxIcons/play.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 12px;
}
.IndividualActivityBox {
  width: 330px;
  height: 90%;
  box-shadow: 4px 4px 4px 4px #00000040;
  margin: 10px 10px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}
.IndividualActivityBoxInactive {
  width: 330px;
  height: 90%;
  box-shadow: 4px 4px 4px 4px #00000040;
  margin: 10px 10px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}
.IndividualActivityBoxInactive:hover {
  outline: solid 2px #3045ff;
  background: rgb(250, 250, 250);
  cursor: pointer;
}
.IndividualActivityFlexBox {
  width: 270px;
  height: 230px;
  box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  margin: 10px 10px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
}
.IndividualActivityFlexBox:hover {
  /* transform: scale(1.05,1.05); */
  outline: solid 2px #3045ff;
  cursor: pointer;
  /* background: rgb(245, 245, 245); */
}
.IndividualActivityBox:hover {
  /* transform: scale(1.05,1.05); */
  outline: solid 2px #3045ff;
  background: rgb(245, 245, 245);
  cursor: pointer;
}
.IndividualActivityFlexBoxInactive {
  width: 280px;
  height: 280px;
  box-shadow: 4px 4px 4px 4px #00000040;
  margin: 10px 10px;
  border-radius: 5px;
  position: relative;
}
.CompletedTag {
  position: absolute;
  color: white;
  border-radius: 5px;
  background: #4cbb17;
  width: 22.5%;
  height: 12%;
  left: 70%;
  top: 80%;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.7rem;
}
.OverdueTag {
  background: rgb(187, 23, 23);
  font-size: 9px;
  color: white;
  border-radius: 5px;
  position: absolute;
  top: 85%;
  right: 5%;
  padding: 5px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
  font-weight: 500;
}
.homepageStartButton {
  position: absolute;
  color: white;
  border-radius: 5px;
  background: #3045ff;
  width: 22.5%;
  height: 12%;
  left: 81%;
  top: 8%;
  border-color: transparent;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif;
  font-size: 0.7rem;
}
.homepageStartButton:hover {
  background: #2434c4;
  cursor: pointer;
}
.HomeAcitivtyBoxCallTypeLabel {
  background: black;
  opacity: 0.75;
  font-size: 9px;
  color: white;
  border-radius: 5px;
  position: absolute;
  top: 15%;
  left: 72%;
  padding: 5px;
  min-width: 60px;
  display: inline-block;
  text-align: center;
}
